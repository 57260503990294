/*------------------------------------------------------------------
[Table of contents]

 - General
 - Main Menu
 - Slider
 - Numbers
 - Blog
 - Footer
 - Search Modal
 - Loader

- Author:  Andrey Sokoltsov
- Profile:	http://themeforest.net/user/andreysokoltsov

-------------------------------------------------------------------*/

/* General */
body{
	overflow-x: hidden;
}
#to-top{
	background-color: rgba(0, 0, 0, 0.6);
	display: none;
	padding: 15px;
	color: #fff;
	position: fixed;
	right: 20px;
	bottom: 20px;
	z-index: 1999;
	border-radius: 3px;
}
.no-decoration,.no-decoration:hover,.no-decoration:visited,.no-decoration:active,.no-decoration:focus{
    text-decoration:none;
}

.dark-bg{
    position:relative;
}

.dark-bg .container{
    z-index:100;
    position: relative;
}

.dark-bg:after{
    position: absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    content:'';
    background: rgba(0,0,0,0.75);
}

.relative-pos{
    position: relative;
}

.no-padding{
    padding: 0;
}

.top-header__main{
    border-bottom:1px solid #eee;
}

.social--top{
    padding: 15px 0 10px 0;
}

.square{
    display: block;
    width:27px;
    height:27px;
    border:1px solid #eee;
    text-align: center;
    padding-top: 3px;
    cursor:pointer;
}

.social__one{
    margin-right: 5px;
    float:left;
    cursor:pointer;
    text-decoration: none;
}

.header-contacts{
    padding: 15px 10px;
}

.header-contacts__one{
    float: right;
    margin-left: 25px;
}

.header-contacts__one a{
    margin-left: 10px;
}

.header-contacts__phone,.header-contacts__email{
    display: inline-block;
}

.header-contacts__email{
    padding-top: 2px;
}

.header-contacts__email span.fa{
    font-size:12px;
}

.header-contacts__link{
    font:12px 'Lato',sans-serif;
    text-decoration: none;
    margin-left: 10px;
}

.header-contacts__link:hover,.header-contacts__link:focus,.header-contacts__link:visited,.header-contacts__link:active{
    text-decoration: none;
}

.top-nav__main{
    padding: 35px 0 35px 0;
    margin-bottom: 0;
    z-index:2000;
    position: relative;
}

.logo{
    display: inline;
    float:left;
    margin-top: -10px;
}

.logo__moto{
    display: inline-block;
    margin-right: 5px;
    -moz-transform: scale(-1, 1);
    -webkit-transform: scale(-1, 1);
    -o-transform: scale(-1, 1);
    transform: scale(-1, 1);
    filter: FlipH;
    -ms-filter: "FlipH";
}

.logo--home img {
    display: inline;
    max-width: 50px;
}


.logo:hover,.logo:focus,.logo:visited,.logo:active{
    text-decoration: none;
}

.logo__title{
    font:700 26px 'Lato',sans-serif;
    display: inline;
    margin: 0;
    vertical-align:center;
    margin-top: 50px;
    padding-top: 10px;
}

.logo__title span{
    font-weight:300;
}


/* Main Menu */
.main-nav{
    float:right;
    margin-top: 8px;
}

.main-nav ul{
    float:right;
}

.main-nav li{
    list-style:none;
    float:left;
    position:relative;
}

.main-nav > ul > li > ul {
	border-radius: 0px;
	padding: 0px;
	box-shadow: none;
	left: 0px;
	margin: 0px;
	min-width: 100px;
}

.main-nav > ul > li > ul li{
    float:none;
    text-align: center;
}

.main-nav > ul > li > ul li a {
	padding: 10px 14px;
	text-align: left;
}

.main-nav li a{
    text-transform: uppercase;
    font:400 13px 'Lato',sans-serif;
    padding: 0 15px;
}

.main-nav__separator{
    display: inline-block;
    vertical-align: middle;
}

.main-nav__separator > span{
    width:2px;
    height:2px;
    margin-bottom: 2px;
    display:block;
    position: relative;
    right:-1px;
}

.main-nav__search{
    float:right;
    display:block;
    width:30px;
    height:30px;
    text-align: center;
    padding-top: 4px;
    margin: -7px 0 0 15px;
}

#nav{
    padding: 0;
    margin-bottom: 0;
}

/* Slider */
.sliders__main{
    padding: 0;
    margin-top: 150px;
    position: relative;
}

.bx-wrapper .bx-viewport{
    box-shadow:none;
    border:none;
    left:0;
    background: none;
}

.sliders .bx-wrapper .bx-prev{
    background: rgba(25,27,39,0.3);
    border-radius: 0;
    width:50px!important;
    height:50px!important;
    margin-top: -25px!important;
    left:0;
    text-align: center;
    padding-top: 14px;
}

.sliders .bx-wrapper .bx-next{
    background: rgba(25,27,39,0.3);
    border-radius: 0;
    width:50px!important;
    height:50px!important;
    margin-top: -25px!important;
    right:0;
    text-align: center;
    padding-top: 14px;
}

.sliders .bx-wrapper .bx-controls-direction a{
    text-indent: 0;
    z-index: 1999;
    opacity: 0.8;
}

.sliders .bx-wrapper .bx-controls-direction a span.fa{
    font-size:16px;
}

.sliders .bx-wrapper .bx-controls-direction a:hover{
    opacity: 1;
}

.sliders__text{
    position: absolute;
    z-index:1000;
    top:25%;
    right:10%;
    left:10%;
    margin-left: 25px;
    max-width:760px;
}


.sliders__text h2{
    text-transform: uppercase;
    margin: 0 0 20px 0;
    font:400 25px 'Lato',sans-serif;
    letter-spacing:2px;
}

.sliders__text h3{
    text-transform: uppercase;
    position: relative;
    margin: 0 0 50px 0;
    padding-bottom: 40px;
    font:900 50px 'Lato',sans-serif;
    letter-spacing:2px;
}

.line{
    display: block;
    position: absolute;
    top:100%;
    left:0;
    overflow:hidden;
}

.line__first,.line__second{
    display: block;
    width: 50%;
    float:left;
    height:4px;
}

.line--slider{
    width:33%;
}

.line--slider .line__first,.line--slider .line__second{
    height:5px;
}

.sliders__text p, .sliders__text img{
    font:300 20px 'Lato',sans-serif;
    margin-bottom: 20px;
}

.button{
   font:700 14px 'Lato',sans-serif;
   text-transform: uppercase;
   padding:15px 25px;
   border-radius:0;
}

.button:hover,.button:focus,.button:visited,.button:active{
    text-decoration:none;
}

.button--red:hover{
    border:1px solid #ddd;
}

.triangle{
    position: relative;
}

.triangle:after{
    width:0;
    height:0;
    content:'';
	border-bottom: 10px solid red; 
	border-right: 10px solid transparent;
    position:absolute;
    bottom:-1px;
    left:-1px;
}

.title{
    font:300 28px 'Lato',sans-serif;
    margin: 0;
}

.title__bold{
    font-weight:700;
    text-transform: uppercase;
}

.anons__block{
    padding: 1px 1px 35px 1px;
    margin-top: -15px;
    height:251px;
}

.anons__block--center{
    padding: 0;
    box-shadow:0 0 40px rgba(0,0,0,0.3);
    margin-top: -82px;
    height:318px;
}

.anons__block h2{
    margin: 0 0 15px 0;
    text-align: center;
}

.anons__img{
    height:125px;
    width:125px;
    margin: -30px auto 45px auto;
    position: relative;
    z-index: 1999;
    box-shadow: 0 0 20px rgba(0,0,0,0.1);
    padding-top: 35px;
    text-align: center;
}

.anons__img--bike{
    padding-top: 15px;
}

.anons__img span.fa{
    font-size:50px;
}

.triangle--block:after{
    border-bottom: 13px solid red; 
	border-right: 13px solid transparent;
    bottom:0px;
    left:0px;
}

.triangle--block-center:after{
    border-bottom: 13px solid red; 
	border-right: 13px solid transparent;
    left:0;
    bottom:0;
}

.line-down{
    border-bottom: 2px solid #ce0000;
}

.text{
    font:300 16px 'Lato',sans-serif;
    margin: 0;
    line-height:1;
}

.anons__block p{
    text-align: center;
}

.anons__color-block{
    padding: 35px 0;
    height:143px;
}

.we-are{
   padding: 120px 0 100px 0; 
}

.title--main{
    font-size:36px;
    position: relative;
    padding-bottom: 35px;
    margin:0 0 0px 0;
}

.line--title{
    width:100px;
}

.line--title .line__first{
    height:4px;
    margin-right: 2%;
    width:49%;
}

.line--title .line__second{
    height:4px;
    width:49%;
}

.text--anons{
    font:300 20px 'Lato',sans-serif;
    line-height:1.5;
    margin-bottom: 25px;
}

.we-are__img{
    margin-bottom: 30px;
}

.we-are__block{
    padding: 40px 80px 35px 50px;
}

.title--block{
    font-size:24px;
    margin: 0 0 25px 0;
}

.we-are__block p.text{
    margin-bottom:50px ;
}

.button--main{
    width:100px;
    padding:12px 0;
    font:700 11px 'Lato',sans-serif;
}

.triangle--big:after{
    border-bottom: 17px solid red;
    border-right: 17px solid transparent;
    left:0;
    bottom:0;
}

.we-are > .container >.row > div:last-child .we-are__img{
    margin: 30px 0 0 0;
}

/* Numbers */
.numbers{
     
}

.numbers > .container > div{
    margin-bottom: -5px;
    padding-bottom: 130px;
    position: relative;
    z-index:100;
}

.numbers__one{
    width:16%;
    float:left;
    margin-right: 5%;
}

.numbers__one:last-child{
    margin-right: 0;
}

.numbers__head{
    position: relative;
    padding-bottom: 25px;
    margin-bottom: 35px;
}

.numbers__head span.fa{
    font-size:40px;
}

.line--slim{
    width:100%;
}

.line--slim .line__first{
    width:34%;
    margin-right: 1%;
    height:3px;
}

.line--slim .line__second{
    width:65%;
    height:3px;
}

.numbers__num{
    font:700 32px 'Lato',sans-serif;
}

.number__desc{
    font:300 26px 'Lato',sans-serif;
}

.offers{
    padding: 100px 0;
    background-image: url(../images/backgrounds/offers.jpg);
    background-size: cover;
}

.offers .owl-carousel .owl-item:nth-child(2n-1) .item{
    padding-right: 15px;
}

.offers .owl-carousel .item{
    padding:0 15px;
}

.offers .owl-controls{
    margin-top: 85px;
}

.offer{
    overflow: hidden;
}

.title__bold span{
    text-transform: none;
}

.offers h2.title{
    text-align: center;
    margin-bottom: 90px;
}

.line--small{
   width:100px;
}

.offers h2.title .line--small{
    left:50%;
    margin-left: -50px;
}

.offer__img{
    float:left;
    border-top:2px solid #ce0000;
}

.offer__info{
    padding: 25px 5px 20px 30px;
    overflow: hidden;
    margin-top: 25px;
    height:247px;
}

.offer__info h4.title{
    font-size:22px;
    margin-bottom:10px;
}

.offer__price{
    font:400 16px 'Lato',sans-serif;
    margin-bottom: 30px;
}

.offer__price span{
    display: inline-block;
    font:700 20px 'Lato',sans-serif;
    position: relative;
    bottom:-2px;
    margin:0 4px;
}

.list{
    padding: 0;
    list-style:none;
}

.offer .list{
    margin-bottom: 30px;
}

.list li{
    font:300 14px 'Lato',sans-serif;
    margin-bottom: 8px;
}

.list li:last-child{
    margin-bottom: 0;
}

.list__circle{
    width:12px;
    height:12px;
    border:1px solid #cd0000;
    border-radius:10px;
    display: inline-block;
    position: relative;
    vertical-align: baseline;
    margin-right: 10px;
}

.list__circle span.fa{
    font-size:8px;
    position: absolute;
    top:50%;
    margin-top: -4px;
    left:50%;
    margin-left: -4px;
}

.services{
    background: #f6f6f6; /* Old browsers */
    background: -moz-linear-gradient(-45deg,  #f6f6f6 0%, #fbfbfb 38%, #f7f7f7 39%, #ffffff 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, right bottom, color-stop(0%,#f6f6f6), color-stop(38%,#fbfbfb), color-stop(39%,#f7f7f7), color-stop(100%,#ffffff)); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(-45deg,  #f6f6f6 0%,#fbfbfb 38%,#f7f7f7 39%,#ffffff 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(-45deg,  #f6f6f6 0%,#fbfbfb 38%,#f7f7f7 39%,#ffffff 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(-45deg,  #f6f6f6 0%,#fbfbfb 38%,#f7f7f7 39%,#ffffff 100%); /* IE10+ */
    background: linear-gradient(135deg,  #f6f6f6 0%,#fbfbfb 38%,#f7f7f7 39%,#ffffff 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f6f6f6', endColorstr='#ffffff',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
}

.services__main{
    padding: 100px 0 55px 0;
    margin-top: -25px;
}

.after{
    position: relative;
}

.after:after{
    position: absolute;
    height:5px;
    left:0;
    top:100%;
    width:100%;
    content:'';
    z-index:1000;
}

.services .container > div{
    
}

.square--toggle{
    border:none;
    padding-top: 7.5px;
    font-size:12px;
}

.services__info{
    border-bottom:1px solid #eee;
}

.services__title{
    margin: 0 0 0 20px;
    padding:10px 0 10px 0;
    position: relative;
    overflow: hidden;
}

.services__title:before{
    content:'';
    position: absolute;
    top:0;
    left:0;
    width:100%;
    border-top:1px solid #eee;
    margin-left: 10px;
}

.services__info-block:first-child .services__title:before{
    margin-left: 20px;
}

.services__title a:first-child{
    font:400 15px 'Lato',sans-serif;
    margin-top: 5px;
}

.services__title a:first-child:hover{
    font-weight:bold;
}

.services__text{
    margin:0 0 0 0;
    padding-left: 20px;
    font:300 14px 'Lato',sans-serif;
    line-height:1.5;
    padding-bottom: 35px;
    display:none;
}

.triangle--services:after{
    border-bottom: 12px solid red;
    border-right: 12px solid transparent;
    left:0;
    bottom:0;
}

.services__info-block--active .services__text{
    display: block;
}

.services__info-block--active .services__title a:first-child{
    font-weight: bold;
}

.reviews{
    padding: 120px 0 100px 0;
    background: url(../images/backgrounds/reviews.jpg);
    background-size: cover;
    position: relative;
}

.reviews .container{
    z-index: 100;
    position: relative;
}

.reviews:after{
    background-color: rgba(206,0,0,0.9);
    content:'';
    position: absolute;
    top:0;
    left:0;
    height:100%;
    width:100%;
}

.review{
    padding: 40px 0;
}

.review__img{
    position: relative;
    float:left;
}

.review__quote{
    width:80px;
    height:80px;
    right: -40px;
    top:-40px;
    position: absolute;
    text-align: center;
    padding-top: 25px;
}

.review__quote span.fa{
    font-size:30px;
}

.review__text{
    float:left;
    margin-left: 55px;
    position: relative;
}

.row--no-padding{
    margin: 0;
}

.row--no-padding > div{
    padding: 0;
}

.review__text .review__main-text{
    font:italic 700 20px 'Lato',sans-serif;
    line-height:1.7;
    padding-bottom: 50px;
    position: relative;
    margin-bottom: 30px;
}

.review__author{
   font:700 16px 'Lato',sans-serif; 
}

.review__author span{
    font:400 13px 'Lato',sans-serif;
    margin: 0 0 0 8px;
}

.reviews .bx-wrapper{
    position: static;
}

.reviews .bx-wrapper .bx-prev{
    background: none;
    border-radius: 0;
    width:40px!important;
    height:40px!important;
    left: calc(100% + 50px);
    top:50%;
    text-align: center;
    padding-top: 10px;
}

.reviews .bx-wrapper .bx-next{
    background: none;
    border-radius: 0;
    width:40px!important;
    height:40px!important;
    left: calc(100% + 50px);
    text-align: center;
    padding-top: 10px;
    top:calc(50% + 50px);
}

.reviews .bx-wrapper .bx-controls-direction a{
    text-indent: 0;
    border:1px solid #cf6f6f;
    text-align: center;
    display: inline-block;
}

.reviews .bx-wrapper .bx-controls-direction a span.fa{
    font-size:16px;
}

.reviews .bx-controls-direction{
    text-align: center;
}

.bx-wrapper .bx-controls-direction a.static:last-child{
    margin-right: 0;
}

.featured{
    padding: 100px 0 120px 0;
    background-image: url(../images/backgrounds/featured.jpg);
    background-size: cover;
}

.featured h2.title{
    margin-bottom: 70px;
}

.featured__images a > img{
    margin-bottom: 23px;
}

.featured__images .video{
    border:none!important;
    height:120px!important;
    width:100%!important;
    overflow: hidden;
}
.featured__images .video > img{
	width: 100%;
	height: auto;
}

.featured__info{
    margin-left: 10px;
}

.featured__info > h4{
   font:300 24px 'Lato',sans-serif;
   margin:0 0 25px 0; 
}

.featured__info > h4 a span{
    font-weight:bold;
}

.featured__info > p.text{
    font-size:18px;
    margin-bottom: 40px;
}

.technical > h5{
     font:700 18px 'Lato',sans-serif;
     margin-bottom: 30px;
}

.technical__title{
    font:400 14px 'Lato',sans-serif;
    margin: 0 0 10px 0;
}

.technical__val{
    font:300 14px 'Lato',sans-serif;
    margin: 0 0 10px 0;
}

.featured .technical .bx-prev{
    background: none;
    border-radius: 0;
    margin-left: 15px;
}

.featured  .technical .bx-next{
    background: none;
    border-radius: 0;
    margin-left: 10px;
}

.featured  .technical > a{
    text-indent: 0;
    width:auto;
    height:auto;
    position: static;
    display: inline-block;
    margin-top: 5px;
    
}

.featured .technical a span.fa{
    font-size:18px;
}

.bx-viewport ul{
    padding: 0;
}

.listings{
    padding: 95px 0 125px 0;
    background: #f6f6f6; /* Old browsers */
    background: -moz-linear-gradient(-45deg,  #f6f6f6 0%, #fbfbfb 38%, #f7f7f7 39%, #ffffff 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, right bottom, color-stop(0%,#f6f6f6), color-stop(38%,#fbfbfb), color-stop(39%,#f7f7f7), color-stop(100%,#ffffff)); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(-45deg,  #f6f6f6 0%,#fbfbfb 38%,#f7f7f7 39%,#ffffff 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(-45deg,  #f6f6f6 0%,#fbfbfb 38%,#f7f7f7 39%,#ffffff 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(-45deg,  #f6f6f6 0%,#fbfbfb 38%,#f7f7f7 39%,#ffffff 100%); /* IE10+ */
    background: linear-gradient(135deg,  #f6f6f6 0%,#fbfbfb 38%,#f7f7f7 39%,#ffffff 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f6f6f6', endColorstr='#ffffff',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
}

.tab-toggles{
    font-size:0;
}

.button--grey{
    border:1px solid #ddd;
}

.button--active{
    position: relative;
}

.button--active:after{
    width: 0;
    height: 0;
    content: '';
    border-bottom: 7px solid red;
    border-right: 7px solid transparent;
    position: absolute;
    bottom: -1px;
    left: -1px;
}

.listing-anons{
    display: block;
    position: relative;
}

.triangle.line-down:after{
    bottom: -1px;
}

.listing-anons__title > h4{
    font:400 14px 'Lato',sans-serif;
    text-transform: uppercase;
    margin: 0;
    padding: 35px 0;
    text-align: center;
}

.listing-anons__hidden{
    padding: 35px 5px;
    position: absolute;
    bottom: 0;
    left:0;
    width:100%;
    text-align: center;
    display: none;
}

.listing-anons:hover .listing-anons__hidden{
    display: block;
}

.listing-anons.line-down.triangle:hover:after{
    bottom: -2px;
    border-bottom: 12px solid red;
    border-right: 12px solid transparent;
}

.listing-anons__hidden > h3{
    margin: 0 0 10px 0;
    font:700 14px 'Lato',sans-serif;
}

.listing-anons__hidden > p{
    margin: 0 0 10px 0;
    font:300 14px 'Lato',sans-serif;
}

/* Blog */
.blog__info{
    margin: 0 60px 0 85px;
    padding: 95px 0 0 0;
}

.blog__info p.text{
    margin-bottom: 90px;
}

.blog-anons{
    position: relative;
    cursor: pointer;
    display: block;
}

.blog-anons__hidden{
   position:absolute;
   top:0;
   left:0;
   height:100%;
   width:100%;
   background: rgba(0,0,0,0.7);
   padding: 0 40px;
   display: none;
}

.blog-anons:hover .blog-anons__hidden{
    display: block;
}

.blog-anons__hidden > h3{
    font:700 20px 'Lato',sans-serif;
    margin: 0 0 10px 0;
}

.blog-anons__info > span:first-child{
    margin-right: 30px;
}

.blog-anons__info > span{
    font:700 12px 'Lato',sans-serif;
}

.blog-anons__info span.fa{
    margin-right: 7px;
    font-size:18px;
}

.blog-anons__info span.fa.fa-comment-o{
    -moz-transform: scale(-1, 1);
    -webkit-transform: scale(-1, 1);
    -o-transform: scale(-1, 1);
    transform: scale(-1, 1);
    filter: FlipH;
    -ms-filter: "FlipH";
}

.blog-anons__img img{
    width:100%;
}

.triangle--bigger:after{
    border-bottom: 22px solid red;
    border-right: 22px solid transparent;
    left:0;
    bottom: 0;
}

.blog-anons__text{
    position:  absolute;
    bottom: 20%;
    left:5%;
}

.blog-anons__text h3{
    font:700 20px 'Lato',sans-serif;
    margin: 0 0 10px 0;
}

.partners{
    text-align: center;
    padding: 90px 0 60px 0;
    border-bottom:1px solid #eeeeee;
    background: #f6f6f6; /* Old browsers */
    background: -moz-linear-gradient(-45deg,  #f6f6f6 0%, #fbfbfb 38%, #f7f7f7 39%, #ffffff 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, right bottom, color-stop(0%,#f6f6f6), color-stop(38%,#fbfbfb), color-stop(39%,#f7f7f7), color-stop(100%,#ffffff)); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(-45deg,  #f6f6f6 0%,#fbfbfb 38%,#f7f7f7 39%,#ffffff 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(-45deg,  #f6f6f6 0%,#fbfbfb 38%,#f7f7f7 39%,#ffffff 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(-45deg,  #f6f6f6 0%,#fbfbfb 38%,#f7f7f7 39%,#ffffff 100%); /* IE10+ */
    background: linear-gradient(135deg,  #f6f6f6 0%,#fbfbfb 38%,#f7f7f7 39%,#ffffff 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f6f6f6', endColorstr='#ffffff',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
}

.text--small{
    font:300 14px 'Lato',sans-serif;
    line-height:1.7;
    max-width:675px;
    margin: 0 auto 55px auto;
}

.line--center{
    left:50%;
    margin-left: -49px;
}

.brand{
    display: inline-block;
    margin-right: 70px;
}

.brand:last-child{
    margin-right: 0;
}

.signup{
    padding: 70px 0 160px 0;
}

.signup__desc{
    margin: 0 0 0 50px;
}

.signup__desc h2.title{
    padding-bottom: 0;
}

.signup__desc span.fa{
    margin-right: 10px;
}

.signup .sign-form{
    margin-top: 90px;
}

.signup .sign-form > div{
    width:460px;
    float:left;
    margin-right: 10px;
}

.sign-input{
    font: italic 300 16px 'Lato',sans-serif;
    border:none;
    border-bottom:1px solid #eee;
    padding:0 0 28px 25px;
    width:100%;
    background-color: transparent!important;
}

.sign-input:focus{
    outline:none;
}

.sign-input::-webkit-input-placeholder {
    color:#bbb;
}

.sign-input::-moz-placeholder{
    color:#bbb;
}

.sign-input:-moz-placeholder{
    color:#bbb;
}

.sign-input:-ms-input-placeholder{
    color:#bbb;
}

.input-triangle{
    width: 0;
    height: 0;
    content: '';
    border-bottom: 12px solid #eee;
    border-right: 12px solid transparent;
    position: absolute;
    bottom: 0px;
    left: 0px;
}

.button--sign{
    padding: 15px 35px;
    font-weight:400;
}

.triangle--12:after{
    border-bottom: 12px solid #eee;
    border-right: 12px solid transparent;
}

.link{
    padding-bottom: 70px;
}

.link__square{
    width:90px;
    height:90px;
    text-align: center;
    margin: 0 auto 45px auto;
    margin-top: -45px;
    box-shadow: 0 0 20px rgba(0,0,0,0.1)
}

.link__square.triangle:after{
    left:0;
    bottom: 0;
}

.link__square span.fa-mobile{
    font-size:45px;
    margin-top: 20px;
}

.link__square span.fa-map-marker{
    font-size:35px;
    margin-top: 27px;
}

.link__square span.fa-envelope-o{
    font-size:25px;
    margin-top: 32px;
}

.link__phone > h3{
    font: 700 16px 'Lato',sans-serif;
    margin: 0 0 25px 0;
    text-align: center;
}

.link__phone > p{
    font: 300 16px 'Lato',sans-serif;
    text-align: center;
}

.quick-form{
    margin:45px 0 0 15px;
}

.quick-form .title{
    font-size:18px;
    margin: 0 0 25px 0;
}

.quick-input{
    position: relative;
}

.quick-input{
    margin-bottom: 20px;
    border-bottom:1px solid #555;
    padding:0 0 17px 15px;
}

.quick-input input,.quick-input textarea{
    border:none;
    width:100%;
    font: 400 12px 'Lato',sans-serif;
    resize: none;
}

.quick-input input:focus,.quick-input textarea:focus{
    outline:none;
}

.quick-input input::-webkit-input-placeholder {
    color:#888;
}

.quick-input input::-moz-placeholder{
    color:#888;
}

.quick-input input:-moz-placeholder{
    color:#888;
}

.quick-input input:-ms-input-placeholder{
    color:#888;
}

.quick-input textarea::-webkit-input-placeholder {
    color:#888;
}

.quick-input textarea::-moz-placeholder{
    color:#888;
}

.quick-input textarea:-moz-placeholder{
    color:#888;
}

.quick-input textarea:-ms-input-placeholder{
    color:#888;
}

.triangle-quick-input{
    width: 0;
    height: 0;
    content: '';
    border-bottom: 8px solid #888;
    border-left: 8px solid transparent;
    position: absolute;
    bottom: 0px;
    right: 0px;
}

.quick-form .button{
    width:120px;
}

/* Footer */
.footer-bottom{
    padding: 28px 0;
}

.copyright{
    font: 400 12px 'Lato',sans-serif;
    margin-top: 10px;
}

.copyright p{
    margin: 0;
}

.copyright p span{
    font-weight:bold;
    margin: 0 3px;
}

.social--footer .square{
    width:35px;
    height:35px;
    padding-top: 6px;
}

.reviews--yellow{
    background-image: url(../images/backgrounds/reviews.jpg);
}


.top-nav__main--home{
    margin-bottom: 0;
    padding-bottom: 10px;
    padding-top: 45px;
    background: transparent!important;
    height:100px;
}

.sliders--home{
    top: -100px;
    position: relative;
    margin-bottom: -100px;
    background-image: url(../images/backgrounds/slider2.jpg);
    background-size: cover;
    min-height: 600px;
}

.top-nav__main--home .main-nav__search{
    background: transparent!important;
    margin-left: 5px;
}

.biker-club__main{
    padding: 0 30px 0 40px;
}

.biker-club__upcoming{
    padding: 35px 0 40px 0;
}

.biker-club__upcoming .title{
    margin: 0 0 5px 0;
}

.title-space{
    text-transform: uppercase;
    font: 700 16px 'Lato',sans-serif;
    letter-spacing:4px;
    margin:0 0 25px 0;
}

.biker-club__upcoming .text{
    font-size:15px;
    margin-bottom: 30px;
}

.biker-club__upcoming .button{
    width:auto;
    padding-right:15px;
    padding-left:15px;
}

.event{
    position: relative;
    top:45px;
}

.event__info{
    padding: 35px 0 0 35px;
}

.event__title{
    font: 700 20px 'Lato',sans-serif;
    margin: 0 0 5px 0;
}

.event__time{
    font: 400 14px 'Lato',sans-serif;
    margin-bottom: 30px;
}

.event__addr-date{
    font: 400 14px 'Lato',sans-serif;
    margin-top: 20px;
}

.event__addr-date span.fa{
    font-size:20px;
    display: block;
    float: left;
    margin-right: 15px;
}

.event__info .button{
    margin-top: 25px;
}

.we-are--home{
    padding-bottom: 0;
    margin-top: -150px;
}

.images__one img{
    width:100%;
}

.info-blocks{
    padding: 75px 0 115px 0;
}

.info-block .text{
    margin-bottom: 45px;
}

.numbers--home > .container > div{
    padding-bottom: 160px;
}

.home-reviews{
    background-image: url(../images/backgrounds/home-reviews.jpg);
    background-size: cover;
    padding-bottom: 85px;
}

.home-reviews__quote{
    width:90px;
    height:90px;
    margin: 0 auto;
    margin-top: -35px;
    position: relative;
    z-index: 1001;
    padding-top: 30px;
    text-align: center;
    margin-bottom: 75px;
}

.home-reviews__review{
    padding: 0 15px;
}

.home-reviews__quote span.fa{
    font-size:30px;
}

.home-reviews__quote.triangle:after{
    bottom:0;
    left:0;
    border-bottom:16px solid #fff;
    border-right:16px solid transparent;
}

.person{
    width:125px;
    height:125px;
    border-radius:100%;
}

.person--first{
    background: url(%PUBLIC_URL%/media/persons/person-first.jpg) center no-repeat;
}

.person--second{
    background: url(%PUBLIC_URL%/media/persons/person-second.jpg) center no-repeat;
}

.home-reviews__person{
    margin-bottom: 25px;
}

.home-reviews__text > p{
    font:italic 400 17px 'Lato',sans-serif;
    line-height:1.7;
    margin-bottom: 30px;
}

.home-reviews__author{
    font:700 17px 'Lato',sans-serif;
    margin-bottom: 10px;
    margin-top: 20px;
}

.home-reviews__position{
    font:400 20px 'Lato',sans-serif;
}

.home-reviews .owl-controls{
    margin-top: 70px;
}

.blog--home .blog__info .button{
    border:1px solid #d45858;
}

.footer-logo{
    padding: 35px 0 30px 0;
}

.footer-main-home{
    background-image: url(../images/backgrounds/footer.jpg);
    background-size: cover;
    padding: 70px 0;
}

.footer-main-home__block--margin{
    margin-right: 5px;
}

.footer-main-home__title{
    text-transform: uppercase;
    font:400 24px 'Lato',sans-serif;
    margin: 0 0 35px 0;
}

.text--footer-main-home{
    font:300 14px/1.5 'Lato',sans-serif;
}

.footer-main-home__block .button{
    margin-top: 35px;
    font:400 12px 'Lato',sans-serif;
}

.footer-main-home__event{
    padding: 0 0 15px 0;
    border-bottom:1px solid #444;
    margin-bottom: 23px;
}

.footer-main-home__event:last-of-type{
    margin-bottom: 0;
}

.event-num{
    width:40px;
    height:40px;
    font:700 16px 'Lato',sans-serif;
    padding-top: 10px;
    text-align: center;
}

.event-num.triangle:after{
    bottom: 0;
    left:0;
    border-bottom:8px solid #ce0000;
    border-right:8px solid transparent;
}

.footer-main-home__event-text{
    margin-right: 45px;
}

.footer-main-home__event-text > h3{
    font:400 14px 'Lato',sans-serif;
    margin: 0 0 5px 0;
}

.footer-main-home__event-text .text{
    font-size:13px;
    font-style:italic;
}

.footer-main-home__block--img img{
    margin-bottom: 15px;
}

.phone{
   font:700 20px 'Lato',sans-serif;
   margin-bottom: 20px;
}

.footer-main-home__address-title{
    margin: 0 0 10px 0;
    font:700 12px 'Lato',sans-serif;
    text-transform: uppercase;
}

.email{
    margin-top: 10px;
    margin-bottom: 20px;
    font:500 20px 'Lato',sans-serif;
}

.facebook img {
    width: 170px;
}

.footer-bottom-home{
    padding: 30px 0;
    text-align: center;
}

.footer-bottom-home .copyright{
    margin: 0;
}

.button--transparent{
    background: transparent;
    border:1px solid #fff;  
}

.biker-club-we{
    background-image: url(../images/backgrounds/club-home.png);
    background-size: cover;
    background-position-x:-400px ;
}

.biker-club-we .biker-club,.biker-club-we .we-are{
    background: transparent;
}

.numbers--home2 > .container > div{
    margin-bottom: 0;
}

.numbers--home2 .line .line__first{
    width:35%;
    margin: 0;
}

.services--home2{
    background: none;
}

.services--home2 .services__info-block:first-child .services__title:before,.services--home2 .services__title:before{
    margin-left: 0;
}

.services--home2 .services__title{
    margin-left: 0;
    padding-left: 20px;
}

.listings--home2{
    background-image: url(../images/backgrounds/listings-home.jpg);
    background-size: cover;
}

.listings--home2 .button--active:after{
    display: none;
}

.listings--home2 .listing-anons{
    background-color: transparent;
}

.partners--home2{
    background: none;
}

.partners--home2 .brand{
    padding-bottom: 15px;
    padding-top: 15px;
}

/* Search Modal */
.transition{
	-webkit-transition:all 300ms ease 0s;
	-moz-transition:all 300ms ease 0s;
	-o-transition:all 300ms ease 0s;
	transition:all 300ms ease 0s;
}
.search-form-modal{
	background-color:#000;
	height:0;
	left:0;
	overflow:hidden;
	position:fixed;
	top:0;
	width:100%;
	z-index:9999;
}
.search-form-modal.open{
	height:100%
}
.search-form_close{
	color:#fff;
	cursor:pointer;
	font-size:20px;
	position:absolute;
	right:15px;
	top:-20px;
}
.header_search_form{
	bottom:0;
	display:block;
	height:80px;
	left:0;
	margin:auto;
	max-width:550px;
	padding:15px;
	position:absolute;
	right:0;
	top:0;
	width:100%;
	box-shadow: none;
}
.header_search_form .form-group{
	float:left;
	height:100%;
	width:68%;
}
.header_search_form .form-group .form-control{
	border-color:#fff;
	border-style:solid none solid solid;
	border-width:2px 0 2px 2px;
	font-size:16px;
	height:100%;
	float:left;
	width:100%;
	padding:10px 15px;
	border-radius:0;
	-moz-border-radius:0;
	-webkit-border-radius:0;
	box-shadow:none;
	-moz-box-shadow:none;
	-webkit-box-shadow:none;
}
.header_search_form .btn_search{
	border:2px solid #fff;
	color:#fff;
	float:right;
	font-size:16px;
	height:100%;
	text-transform:uppercase;
	width:32%;text-shadow:none;
	-moz-text-shadow:none;
	-webkit-text-shadow:none;
	border-radius:0;
	-moz-border-radius:0;
	-webkit-border-radius:0;
	box-shadow:none;
	-moz-box-shadow:none;
	-webkit-box-shadow:none;
}

/* Loader */
.preloaderjs .spinner{
	display:none !important;
}
.preloaderjs#page-preloader{
	background:  rgba( 46, 46, 46, 0.99)  !important;
}
#page-preloader {
	position: fixed;
	z-index: 100500;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: #555;
}
#page-preloader .spinner {
	position: absolute;
	z-index: 1001;
	top: 50%;
	left: 50%;
	display: block;
	width: 100px;
	height: 100px;
	margin-top: -50px;
	margin-left: -50px;
	-webkit-animation: spin 2.5s infinite linear;
	animation: spin 2.5s infinite linear;
	border: 3px solid transparent;
	border-top-color: #3cd225;
	border-radius: 50%;
}
#page-preloader .spinner:before, #page-preloader .spinner:after {
	position: absolute;
	content: '';
	border-radius: 50%;
}
#page-preloader .spinner:before {
	top: 5px;
	right: 5px;
	bottom: 5px;
	left: 5px;
	-webkit-animation: spin 2s infinite linear;
	animation: spin 2s infinite linear;
	border: 3px solid transparent;
	border-top-color:#2475ce;
}
#page-preloader .spinner:after {
	top: 15px;
	right: 15px;
	bottom: 15px;
	left: 15px;
	-webkit-animation: spin 1s infinite linear;
	animation: spin 1s infinite linear;
	border: 3px solid transparent;
	border-top-color: #ce0000;
}
@-webkit-keyframes spin {
	0% {
	-webkit-transform: rotate(0);
	transform: rotate(0);
}
100% {
	-webkit-transform: rotate(360deg);
	transform: rotate(360deg);
}
}
@keyframes spin {
	0% {
	-webkit-transform: rotate(0);
	transform: rotate(0);
}
100% {
	-webkit-transform: rotate(360deg);
	transform: rotate(360deg);
}
}

/*custom */
.motormounts {
    margin-top: 100px;
}

.imagePost {
    padding-top: 25px;
    padding-bottom: 35px;
}

.imagePost img{
    max-width: 550px;
}