/*------------------------------------------------------------------
[Table of contents]

 - Document

- Author:  Andrey Sokoltsov
- Profile:	http://themeforest.net/user/andreysokoltsov

-------------------------------------------------------------------*/
.customBgColor{
	background-color: #f76e2b;
}
header{
	border: 0 none;
}
.documentation-row{
	border-top: 1px solid #eee;
	background-color: #fcfcfc;
	padding: 50px 0;
}
.documentation-row p{
	line-height: 22px;
	font-size: 14px;
	color: #333;
}
.documentation-row .alert-success {
	background-color: #00b200;
	border-color: #00b200;
	color: #ffffff;
}
.documentation-row ul{
	padding-left: 30px;
}
.documentation-row ul li{
	list-style-type: disc;
	margin: 10px 0;
}
.documentation-row .heading-2{
	margin: 30px 40px;
}
.documentation-row .pre{
	color: #fff;
	display: block;
	font-size: 20px;
	height: auto;
	line-height: 20px;
	margin: 10px 0;
	padding: 10px;
}