/*//////////////////////////////////////////////////////////////////////////////////////////*/
/* CUSTOM CSS (ADD YOUR CSS HERE!) */
/*//////////////////////////////////////////////////////////////////////////////////////////*/

@media (max-width: 766px) {
    /* CSS that should be displayed if width is equal to or less than 766px goes here */

    .logo--home {
        position: absolute;
        left: 50%;
        margin-left: -100px;
        padding-bottom: 200px;
    }
    
    .logo--home h2 {
        vertical-align:middle;

    }

    .main-nav--home {
        padding-top: 3rem;
        margin-bottom: 10rem;
    }

    .main-nav {
        padding-top: 3rem;
    }
  }
